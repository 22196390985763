div.link-item-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-top: 15px;
  height: 100px;
}

.leftbox {
  width: fit-content;
  border-radius: 15px;
}

.leftbox img {
  width: 79px;
  height: 79px;
  padding: 0;
  border-radius: 15px;
}

.rightbox {
  margin-left: 20px;
}

.rightbox h2 {
  color: var(--text-primary);
  font-size: 1em;
  text-align: left;
  line-height: 19px;
  font-weight: bold;
  font-family: Helvetica;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 61%;
}

.row-1 {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 3px;
  justify-content: left;
}

.discount {
  width: 60px;
  margin-top: 0;
  padding: 5px;
  background-color: var(--discount-bg);
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  color: var(--discount-text);
  line-height: 19px;
  text-align: center;
  width: fit-content;
  position: relative;
  margin-left: 5px;
}

.rightbox p {
  color: var(--text-secondary);
  line-height: 15px;
  font-size: 12px;
  text-align: left;
  font-family: helvetica;
  margin-top: 2px;
  width: 90%;
  overflow: hidden;
  height: 45px;
}

.desktop {
  display: none;
}

a.linkPath {
  text-decoration: none;
  color: inherit;
}
