/* Header Style */

.header_container {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.user_profile {
  width: fit-content;
}

.user_description {
  width: 60%;
  margin-left: 12px;
}

.user_profile {
  display: flex;
  justify-content: center;
}

.user_profile img {
  width: 100%;
  max-width: 130px;
  margin: auto;
}

.divider {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  display: block;
  padding-top: 16px;
  margin-bottom: 40px;
}

.triangle {
  display: inline;
  width: 8px;
}

.user_description h1 {
  font-family: 'korolev';
  font-size: 40px;
  line-height: 48px;
  text-align: left;
  color: var(--text-primary);
  margin-bottom: 0;
  margin-top: 14px;
}
span.h1-s2 {
  font-weight: 200 !important;
}

div.ig_link {
  margin-top: -10px;
}

.ig_link a {
  text-decoration: none;
  color: inherit;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: var(--text-secondary);
}

.header_container p {
  font-family: helvetica;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  letter-spacing: 0.31px;
  width: 99%;
  color: var(--text-secondary);
  padding-bottom: 26px;
  margin-bottom: 0;
}

.openForJob {
  margin-top: 0;
  padding-top: 0;
}
.openForJob img {
  display: inline;
}

p.ofj-p {
  margin-top: 0;
  padding: 0;
  font-size: 12px;
  font-family: Helvetica;
  color: var(--text-primary);
}

p.ofj-p span {
  font-weight: 100;
  color: var(--text-secondary);
}

p.ofj-p img {
  width: 12px;
  margin: auto;
  vertical-align: middle;
  padding: 0;
  margin: 0;
}
